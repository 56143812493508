import React from 'react';

import './GigaLivreHD.css'

const GigaLivreHD = () => (
  <div className='planos-tv'>
    <h2>GIGA LIVRE HD R$ 25,00</h2>
    <p>Com mais de 40 canais da TV aberta.</p>
    <span>Nossa TV funciona diretamente pela internet, permitindo que você assista aos seus programas favoritos em qualquer lugar e a qualquer momento. </span>    
  </div>
);
export default GigaLivreHD;