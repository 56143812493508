import React, { useState } from 'react';
import { Container, Row, Col, Card, Button, Modal } from 'react-bootstrap';
import gigaLivre from '../../../assets/images/tv1.png';
import gigaStart from '../../../assets/images/tv2.png';
import gigaPlus from '../../../assets/images/tv3.png';
import gigaFull from '../../../assets/images/tv4.png';
import tvIcon from '../../../assets/images/television-icon.png';

import GigaLivreHD from './GigaLivreHD/GigaLivreHD';
import GigaStartHD from './GigaStartHD/GigaStartHD';
import GigaPlusHD from './GigaPlusHD/GigaPlusHD';
import GigaFullHD from './GigaFullHD/GigaFullHD';

import './CardTV.css';

const CardTV = () => {
  const [show, setShow] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const plans = [
    {
      id: 1,
      name: "GIGA LIVRE HD",
      channels: "+ 40 CANAIS",
      extraChannels: "Canais de TV aberta",
      image: gigaLivre,
      component: <GigaLivreHD />,
    },
    {
      id: 2,
      name: "GIGA START HD",
      channels: "24 CANAIS",
      extraChannels: "+40 CANAIS LIVRES",
      image: gigaStart,
      component: <GigaStartHD />,
    },
    {
      id: 3,
      name: "GIGA PLUS HD",
      channels: "47 CANAIS",
      extraChannels: "+ 40 CANAIS LIVRES",
      image: gigaPlus,
      component: <GigaPlusHD />,
    },
    {
      id: 4,
      name: "GIGA FULL HD",
      channels: "56 CANAIS",
      extraChannels: "+40 CANAIS LIVRES",
      image: gigaFull,
      component: <GigaFullHD />,
    }
  ];

  const handleShow = (plan) => {
    setSelectedPlan(plan);
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setSelectedPlan(null);
  };

  return (
    <Container className="tv-plans my-5">
      <h1 className="text-center-title mb-4">Planos de TV</h1>
      <Row>
        {plans.map(plan => (
          <Col key={plan.id} md={6} lg={3}>
            <Card className="plan-card text-center">
              <Card.Img variant="top" src={plan.image} alt={plan.name} className="plan-image" />
              <Card.Body>
                <Card.Title><div className='img-tv'><img src={tvIcon} alt='icon' /></div> {plan.name}</Card.Title>
                <Card.Text>{plan.channels}</Card.Text>
                {plan.extraChannels && <Card.Text className='mb-2'>{plan.extraChannels}</Card.Text>}
                <Button variant="primary" onClick={() => handleShow(plan)}>Mais informações</Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      {selectedPlan && (
        <Modal show={show} onHide={handleClose} size="lg" dialogClassName="custom-modal-dark">
          <Modal.Header closeButton>
            <Modal.Title><div className='icon-tv'><img src={tvIcon} alt='icon' />{selectedPlan.name}</div> </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='custom-modal-dark-body'>
            {selectedPlan.component}
            <img src={selectedPlan.image} alt={selectedPlan.name} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="plan-tv-footer">
              <p className="mb-0">
                Baixe nosso aplicativo Gigabyte Play e começe a aproveitar, mesmo que você não seja assinante da nossa internet.
              </p>
              <Button variant="secondary" onClick={handleClose}>
                Fechar
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </Container>
  );
};

export default CardTV;
