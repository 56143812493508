import React, { useState, useEffect } from "react";
import axios from "axios";
import Carousel from 'react-bootstrap/Carousel';
import IconTV from '../../../assets/icons/tv.png';

import './styles.css';

const CarouselSlideTV = () => {

    const apiUrl = process.env.REACT_APP_API_URL;
    const apiImg = process.env.REACT_APP_API_IMG;


    const [carouselstv, setCarouselstv] = useState([]);

    const TextRender = ({ htmlContent }) => {
        return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
    };

    useEffect(() => {
        fetchCarouselstv();
    }, []);

    const fetchCarouselstv = async () => {
        try {
            const response = await axios.get(`${apiUrl}/carouselstv`);
            setCarouselstv(response.data);
        } catch (error) {
            console.error("Erro ao buscar os carousels:", error);
        }
    };

    return (
        <>
            <Carousel >
                {carouselstv.map((carouseltv) => (
                    <Carousel.Item>
                        <div key={carouseltv._id} >
                            <Carousel.Caption>
                                <div className="card-carousel-title">
                                    <h3>{carouseltv.title}</h3>
                                    <hr />
                                    <div className="card-carousel-title-body">
                                        <img src={IconTV} alt="icon-tv" />
                                        <span>{carouseltv.sub}</span>
                                        <p><TextRender htmlContent={carouseltv.text} /></p>
                                    </div>
                                </div>
                            </Carousel.Caption>
                            <div className="card-carousel-img">
                                <img src={`${apiImg}/assets/carouselstv/${carouseltv.foto}`} alt="Carousel" />
                            </div>
                        </div>
                    </Carousel.Item>
                ))}
            </Carousel>
        </>
    );
}
export default CarouselSlideTV;