import React from 'react';

const GigaStartHD = () => (
  <div>
    <h2>GIGA START HD R$ 65,00</h2>
    <p>O plano GIGA START HD é ideal para quem busca um pouco mais de variedade na programação. 
      Com 24 canais especialmente selecionados e mais 40 canais da TV aberta de cortesia, você terá uma experiência de entretenimento completa. 
    </p>
    <span>
      O Gigabyte Play TV permite que você assista tudo isso pela internet, sem complicações.
    </span>
  </div>
);

export default GigaStartHD;