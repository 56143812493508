import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Form, Button, FormControl, FormGroup } from 'react-bootstrap';

//ICONS
import { ReactComponent as EnvelopeIcon } from '../../assets/img/envelope.svg';
import { ReactComponent as Formation } from '../../assets/img/iconmonstr-task-list-square-filled.svg';
import { ReactComponent as LinkedIn } from '../../assets/img/linkedin.svg';
import { ReactComponent as Github } from '../../assets/img/github.svg';
import { ReactComponent as House } from '../../assets/img/iconmonstr-home-6.svg';
import { ReactComponent as User } from '../../assets/img/iconmonstr-user-5.svg';
import { ReactComponent as Phone } from '../../assets/img/phone.svg';
import { ReactComponent as Education } from '../../assets/img/education-svgrepo-com.svg';
import { ReactComponent as Instagram } from '../../assets/img/instagram.svg';
import { ReactComponent as Facebook } from '../../assets/img/facebook.svg';
import { ReactComponent as Twitter } from '../../assets/img/twitter-3.svg';
import { ReactComponent as Photo } from '../../assets/img/photo.svg';
import Work from '../../assets/img/Daco_4335411.png';

import './styles.css';

const Formulario = () => {
  const [nome, setNome] = useState('');
  const [nascimento, setNascimento] = useState('');
  const [formatoValido, setFormatoValido] = useState(true);
  const [email, setEmail] = useState('');
  const [foto, setFoto] = useState(null);
  const [endereco, setEndereco] = useState('');
  const [telefone, setTelefone] = useState('');
  const [perfil, setPerfil] = useState('');
  const [experiencia, setExperiencia] = useState('');
  const [formacao, setFormacao] = useState('');
  const [linkedin, setLinkedin] = useState('');
  const [github, setGithub] = useState('');
  const [instagram, setInstagram] = useState('');
  const [facebook, setFacebook] = useState('');
  const [twitter, setTwitter] = useState('');


  const handleNomeChange = (event) => {
    setNome(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleFotoChange = (event) => {
    setFoto(event.target.files[0]);
  };

  const handleEnderecoChange = (event) => {
    setEndereco(event.target.value);
  };

  const handleTelefoneChange = (event) => {
    setTelefone(event.target.value);
  };

  const handlePerfilChange = (event) => {
    setPerfil(event.target.value);
  };

  const handleExperienciaChange = (event) => {
    setExperiencia(event.target.value);
  };

  const handleFormacaoChange = (event) => {
    setFormacao(event.target.value);
  };

  const handleLinkedinChange = (event) => {
    setLinkedin(event.target.value);
  };

  const handleGithubChange = (event) => {
    setGithub(event.target.value);
  };

  const handleInstagramChange = (event) => {
    setInstagram(event.target.value);
  };

  const handleFacebookChange = (event) => {
    setFacebook(event.target.value);
  };

  const handleTwitterChange = (event) => {
    setTwitter(event.target.value);
  };

  const handleNascimentoChange = (event) => {
    const inputDate = event.target.value;
    const regex = /^\d{2}\/\d{2}\/\d{4}$/;

    setNascimento(inputDate);

    if (regex.test(inputDate)) {
      setFormatoValido(true);
    } else {
      setFormatoValido(false);
    }
  };

  const handleInvalidFormatFocus = (event) => {
    // Dê foco ao campo quando o formato for inválido
    if (!formatoValido) {
      event.target.focus();
    }
  };

  const [redirecionarParaDetalhes, setRedirecionarParaDetalhes] = useState(false);
  const [idDoCurriculoCadastrado, setIdDoCurriculoCadastrado] = useState('');


  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!formatoValido) {
      // Adicione borda vermelha ao campo e dê foco
      handleInvalidFormatFocus(event);
      event.target.focus();
      toast.error('Formato de data inválido. Insira a data no formato dd/mm/aaaa.', { position: 'top-right' });
      return;
    }

    const formData = new FormData();
    formData.append('nome', nome);
    formData.append('email', email);
    formData.append('foto', foto);
    formData.append('endereco', endereco);
    formData.append('telefone', telefone);
    formData.append('perfil', perfil);
    formData.append('experiencia', experiencia);
    formData.append('formacao', formacao);
    formData.append('linkedin', linkedin);
    formData.append('github', github);
    formData.append('instagram', instagram);
    formData.append('facebook', facebook);
    formData.append('twitter', twitter);
    formData.append('nascimento', nascimento);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/curriculos`, formData);
      const idDoCurriculoCadastrado = response.data._id;
      setIdDoCurriculoCadastrado(idDoCurriculoCadastrado);

      toast.success('Currículo cadastrado com sucesso!', { position: 'top-right' });
      setRedirecionarParaDetalhes(true);
    } catch (error) {
      console.error('Erro ao cadastrar o currículo:', error);
      toast.error('Erro ao cadastrar o currículo.', { position: 'top-right' });
    }
  };

  console.log('redirecionarParaDetalhes:', redirecionarParaDetalhes); // Debug
  console.log('idDoCurriculoCadastrado:', idDoCurriculoCadastrado); // Debug


  return (
    <div className='container'>
      {redirecionarParaDetalhes && idDoCurriculoCadastrado && <Navigate to={`/curriculo/${idDoCurriculoCadastrado}`} />}
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <div className='row'>
            <div className='orientacao'>
              <div className='saudacao'>
                <span>
                  Olá futuro colaborador da Gigabyte Telecom! Estamos animados para conhecê-lo melhor. Por favor, preencha o formulário abaixo para nos contar mais sobre suas experiências, habilidades e personalidade.
                </span>
              </div>
              <div>
                <p>
                  Informar ao menos uma rede social, embora opcional, é uma oportunidade valiosa para nos
                  conectarmos de maneira mais autêntica. Suas redes sociais refletem sua singularidade,
                  permitindo-nos compreender melhor suas paixões. Essa conexão adicional
                  é essencial para construirmos juntos o futuro da tecnologia na Gigabyte Telecom.
                </p>
              </div>
              <hr />
            </div>


            <div className='col-sm-12 col-md-6'>
              <Form.Label htmlFor="nome"> Nome Completo:</Form.Label>
              <div className='text-icon'>
                <span className="input-group-text" id="addon-wrapping"><User /></span>
                <FormControl type="text" placeholder="Digite o nome" id="nome" value={nome} onChange={handleNomeChange} required />
              </div>
              <p>Por favor, informe seu nome completo.</p>

              <Form.Label htmlFor="datanascimento">Data de Nascimento:</Form.Label>
              <div className={`text-icon ${formatoValido ? '' : 'campo-invalido'}`}>
                <span className="input-group-text" id="addon-wrapping"><EnvelopeIcon /></span>
                <FormControl
                  type="text"
                  placeholder="Digite sua data de nascimento"
                  id="nascimento"
                  value={nascimento}
                  onChange={handleNascimentoChange}
                  className={formatoValido ? '' : 'formato-invalido'} // Adiciona classe para feedback visual
                />
              </div>
              <p>Por favor, informe a data no formato dd/mm/aaaa.</p>
              {!formatoValido && <p className="mensagem-erro">Insira sua data de nascimento no formato dd/mm/aaaa.</p>}

              <Form.Label htmlFor="email">E-mail:</Form.Label>
              <div className='text-icon'>
                <span className="input-group-text" id="addon-wrapping"><EnvelopeIcon /></span>
                <FormControl type="email" placeholder="Digite seu e-mail" id="email" value={email} onChange={handleEmailChange} />
              </div>
              <p>Insira seu endereço de e-mail para que possamos entrar em contato.</p>

              <label htmlFor="foto">Foto:</label>
              <div className='text-icon'>
                <span className="input-group-text" id="addon-wrapping"><Photo /></span>
                <FormControl type="file" id="foto" onChange={handleFotoChange} required />
              </div>
              <p>Anexe uma foto sua para que possamos reconhecê-lo. (Dica: Opte por uma foto profissional.)</p>

              <Form.Label htmlFor="endereco">Endereço:</Form.Label>
              <div className='text-icon'>
                <span className="input-group-text" id="addon-wrapping"><House /></span>
                <FormControl type="text" placeholder="Endereço" id="endereco" value={endereco} onChange={handleEnderecoChange} required />
              </div>
              <p>Compartilhe seu endereço atual.</p>

              <Form.Label htmlFor="endereco">Telefone:</Form.Label>
              <div className='text-icon'>
                <span className="input-group-text" id="addon-wrapping"><Phone /></span>
                <FormControl type="text" placeholder="Telefone" id="telefone" value={telefone} onChange={handleTelefoneChange} required />
              </div>
              <p>Informe um número de telefone para facilitar nossa comunicação.</p>

            </div>

            <div className='col-sm-12 col-md-6'>
              <Form.Label htmlFor="linkedin">Linkedin:</Form.Label>
              <div className='text-icon'>
                <span className="input-group-text" id="addon-wrapping"><LinkedIn /></span>
                <FormControl type="text" placeholder="" id="linkedin" value={linkedin} onChange={handleLinkedinChange} required />
              </div>
              <p>Compartilhe os links para suas redes sociais. (Opcional)</p>

              <Form.Label htmlFor="github">Github:</Form.Label>
              <div className='text-icon'>
                <span className="input-group-text" id="addon-wrapping"><Github /></span>
                <FormControl type="text" placeholder="" id="github" value={github} onChange={handleGithubChange} required />
              </div>
              <p>Compartilhe os links para suas redes sociais. (Opcional)</p>

              <Form.Label htmlFor="instagram">Instagram:</Form.Label>
              <div className='text-icon'>
                <span className="input-group-text" id="addon-wrapping"><Instagram /></span>
                <FormControl type="text" placeholder="" id="instagram" value={instagram} onChange={handleInstagramChange} required />
              </div>
              <p>Compartilhe os links para suas redes sociais. (Opcional)</p>

              <Form.Label htmlFor="facebook">Facebook:</Form.Label>
              <div className='text-icon'>
                <span className="input-group-text" id="addon-wrapping"><Facebook /></span>
                <FormControl type="text" placeholder="" id="facebook" value={facebook} onChange={handleFacebookChange} required />
              </div>
              <p>Compartilhe os links para suas redes sociais. (Opcional)</p>

              <Form.Label htmlFor="twitter">Twitter:</Form.Label>
              <div className='text-icon'>
                <span className="input-group-text" id="addon-wrapping"><Twitter /></span>
                <FormControl type="text" placeholder="" id="twitter" value={twitter} onChange={handleTwitterChange} required />
              </div>
              <p>Compartilhe os links para suas redes sociais. (Opcional)</p>
            </div>
          </div>

          <div className='row'>
            <div className='col-sm-12'>
              <Form.Label htmlFor="formacao">Formação Escolar:</Form.Label>
              <div className='text-icon'>
                <span className="input-group-text" id="addon-wrapping"><Education /></span>
                <FormControl as="textarea" placeholder="Digite a sua formação escolar" rows={3} value={formacao} onChange={handleFormacaoChange} required />
              </div>
              <p>Descreva sua formação acadêmica, indicando cursos, instituições e datas.</p>

              <Form.Label htmlFor="perfil">Perfil Pessoal:</Form.Label>
              <div className='text-icon'>
                <span className="input-group-text" id="addon-wrapping"><img src={Work} alt='foto' /></span>
                <FormControl as="textarea" placeholder="Qual o seu perfil pessoal, aptidões e habilidades, ou seja, fale um pouco sobre você" rows={3} value={perfil} onChange={handlePerfilChange} required />
              </div>
              <p>Conte-nos um pouco sobre quem você é, suas paixões e valores.</p>

              <Form.Label htmlFor="experiencia">Experiência Profissional:</Form.Label>
              <div className='text-icon'>
                <span className="input-group-text" id="addon-wrapping"><Formation /></span>
                <FormControl as='textarea' placeholder="Experiencia profissional, empresa onde trabalhou, cargo exercido e a data" rows={3} value={experiencia} onChange={handleExperienciaChange} required />
              </div>
              <p>Liste suas experiências profissionais anteriores. Inclua o nome das empresas, suas funções e as datas de início e término.</p>
            </div>
          </div>
          <div className='orientacao'>
            Observações:

            Certifique-se de preencher todas as informações com precisão.
            Seja transparente e autêntico nas suas respostas.
            Este é um espaço para nos mostrar o que o torna único, então sinta-se à vontade para adicionar detalhes que considere relevantes.
            Obrigado por dedicar seu tempo para preencher nosso formulário! Aguardamos ansiosamente para conhecer mais sobre você e explorar como sua jornada pode se alinhar à Gigabyte Telecom. Boa sorte!
          </div>
          <br />
          {/* {alertMessage && <Alert variant={alertVariant}>{alertMessage}</Alert>} */}
          <Button type="submit">Enviar</Button>
          <hr />
        </FormGroup>
      </Form>


    </div>
  );
};


export default Formulario;
