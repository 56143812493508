import TV from '../../../../assets/images/informa.png';

import './ComboHeader.css';

const ComboHearder = () => {
  return (
    <>
      <div className='combo-header'>
        <img src={TV} alt='tv' />
      </div>
    </>
  )
}
export default ComboHearder;