import React from 'react';
import ContactForm from '../../components/ContactForm/ContactForm';

import './ContactPage.css';
import WhatsApp from '../../components/WhatsApp/WhatsApp';

const ContactPage = () => {
  return (
    <div className="contact-page">
      <h1 className="contact-title">Canais de Atendimento</h1>
      <div className="contact-channels">
        {/* <div className="contact-channel">
          <h2>Telefone</h2>
          <p><a href="tel:+55620800622200">+55 (62) 0800 62 2200</a></p>
        </div>
        <div className="contact-channel">
          <h2>WhatsApp</h2>
          <p><a href="https://wa.me/5511999999999" target="_blank" rel="noopener noreferrer">Enviar mensagem</a></p>
        </div>
        <div className="contact-channel">
          <h2>E-mail</h2>
          <p><a href="mailto:contato@meudominio.com.br">contato@meudominio.com.br</a></p>
        </div> */}
        <div className="social-media">
          <WhatsApp />
        </div>
      </div>
      <h2 className="contact-form-title">Formulário de Contato</h2>
      <ContactForm />
    </div>
  );
};

export default ContactPage;
