import React from 'react';

import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import IpPhoneImage from '../../../assets/images/ipphone.png';
import IpPhoneImage2 from '../../../assets/images/ipphone2.png';
import Phone from '../../../assets/images/phone.png';
import PhoneIcon from '../../../assets/icons/phone.png';

import './CardPrice.css';
import SeparatorBar from '../../SeparatorBar/SeparatorBar';
import Plans from '../Plans/Plans';
import IpPhone from '../IpPhone/IpPhone';

const CardPrice = () => {
  return (
    <>
      {/* <Container fluid className="telephony-service my-5">
        <Row>
          <Col>
            <h1 className="text-center-title mb-4">Gigabyte Telecom - Telefonia Fixa via Internet</h1>
            <p>
              Oferecemos serviços de telefonia fixa através da internet, proporcionando flexibilidade e economia. Basta ter uma conexão de internet e você pode contratar nossos serviços.
            </p>
          </Col>
        </Row>

        <Row className="my-4">
          <Col md={6}>
            <Card className="telephony-card">
              <Card.Img className="card-image" variant="top" src={IpPhoneImage2} alt="Portabilidade" />
              <Card.Body>
                <Card.Title>Portabilidade e Novos Números</Card.Title>
                <Card.Text>
                  Você pode manter seu número atual com a nossa facilidade de portabilidade ou optar por um número novo. Nosso processo é simples e rápido.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="telephony-card">
              <Card.Img className="card-image" variant="top" src={Phone} alt="Equipamentos Antigos" />
              <Card.Body>
                <Card.Title>Aproveite Seus Equipamentos Antigos</Card.Title>
                <Card.Text>
                  Clientes empresariais podem continuar usando seus equipamentos antigos, como telefones e PABX. Nós garantimos a compatibilidade e a eficiência do seu sistema atual.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="my-4">
          <Col md={6}>
            <Card className="telephony-card">
              <Card.Img className="card-image" variant="top" src={IpPhoneImage2} alt="Equipamentos Modernos" />
              <Card.Body>
                <Card.Title>Oferecemos Opções Modernas de IpPhone</Card.Title>
                <Card.Text>
                  Se preferir atualizar seus equipamentos, oferecemos opções de venda ou aluguel de IpPhones, PABX virtual e muito mais. Modernize sua infraestrutura com as melhores tecnologias do mercado.
                  <br />
                  <h3>
                    ATENÇÃO! Aluguel de aparelhos por apenas: R$ 30,00/mês
                  </h3>
                  <span className='consulta'>Observação: Sujeito a consulta.</span>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="telephony-card">
              <Card.Img className="card-image" variant="top" src={IpPhoneImage} alt="Por que escolher Gigabyte Telecom" />
              <Card.Body className='telephony-body'>
                <Card.Title className='title'>Por Que Escolher a Gigabyte Telecom?</Card.Title>
                <div className='list'>
                <ul>
                  <li><i class="fa fa-check-square-o"> </i> Facilidade de uso e instalação</li>
                  <li>Economia em chamadas</li>
                  <li>Portabilidade de números</li>
                  <li>Suporte para equipamentos antigos</li>
                  <li>Opções de equipamentos modernos</li>
                </ul>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="text-center mt-4">
          <Col>
            <Button variant="primary" size="lg">Entre em Contato</Button>
          </Col>
        </Row>
      </Container> */}
      <IpPhone />
      <Plans />
      <SeparatorBar />
    </>
  )
}
export default CardPrice;