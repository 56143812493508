import React, { useEffect, useState } from "react";
import WhatsAppIcon from '../../assets/icons/icons8-whatsapp-48.png';
import Facebook from '../../assets/icons/icons8-facebook-48.png';
import Instagram from '../../assets/icons/icons8-instagram-48.png';

import './WhatsAppStyles.css';

const WhatsApp = () => {

  const message = "Internet, TV por Assinatura, Telefonia Fixa, Internet Rural";

  const [whatsAppLink, setWhatsAppLink] = useState("");

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const baseURL = "https://wa.me/556222000000";
    const messageParam = encodeURIComponent(`Olá, gostaria de falar sobre: ${message}`);

    if (/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/.test(userAgent)) {
      setWhatsAppLink(`whatsapp://send?phone=556222000000&text=${messageParam}`);
    } else {
      setWhatsAppLink(`${baseURL}?text=${messageParam}`);
    }
  }, [message]);

  return (
    <>
      <div className="social-media">  
        <h2 className="icons">
          <a href={whatsAppLink} target="_blank"><img src={WhatsAppIcon} alt="whatsapp-icon" /></a>
          <a target="_blank" href="https://www.facebook.com/gigabytetelecom"><img src={Facebook} alt="facebook-icon" /></a>
          <a target="_blank" href="https://www.instagram.com/gigabytetelecom/"><img src={Instagram} alt="instagram-icon" /></a>
        </h2>
      </div>
    </>
  )
}
export default WhatsApp;