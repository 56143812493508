import GigaPlay from '../../../assets/images/gigaplay-Photoroom.png';
import GigaPlay2 from '../../../assets/images/gigaPlay.png';
import { Button, Row, Col } from 'react-bootstrap';
import { FaGooglePlay, FaApple } from 'react-icons/fa';
import CheckList from './CheckList';
import { useMediaQuery } from 'react-responsive';

import './GigabytePlay.css';

const GigabytePlay = () => {

  const isMobile = useMediaQuery({ maxWidth: 767 });

  const features = [
    "Filmes",
    "Séries",
    "Canais ao vivo",
    "Programação infantil",
    "E muito mais"
  ];
  return (
    <>
      <div className="container-fluid gigaplay-background">
        <div className="container">
          <div className='row'>
            <div className="col-sm-12 col-md-4">
              <h2 className="text-left my-4">Conteúdo do Nosso App</h2>
              <CheckList items={features} />
            </div>
            <div className="col-sm-12 col-md-4">
              <h2 className="text-left my-4">Gigabyte Play</h2>
              <h3>TV na palma da sua mão</h3>
              <span>
                Leve sua TV para qualquer lugar com o App Gigabyte Play para iOS & Android..
              </span>
              <div className="download-button-app">
                <a href="https://play.google.com/store/apps/details?id=com.gigabytemobile&hl=pt_BR&pli=1" target="_blank" rel="noopener noreferrer">
                  <Button variant="dark" className="button-app">
                    <FaGooglePlay className="icon" /> Google Play
                  </Button>
                </a>
                <a href="https://apps.apple.com/br/app/gigabyte-play/id6443457059?l=pt" target="_blank" rel="noopener noreferrer">
                  <Button variant="dark" className="button-app">
                    <FaApple className="icon" /> App Store
                  </Button>
                </a>
              </div>
            </div>
            <div className="col-sm-12 col-md-4">
              <div className='gigaplay'>
                {isMobile ? (
                  <div></div>
                ) : (
                  <div>
                    <h2>Diversão acompanha você</h2>
                    <img src={GigaPlay} alt="giga cliente" />
                  </div>
                )}

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default GigabytePlay;