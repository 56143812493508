import Formulario from "../../components/Formulario/Formulario";
import Jobs from "../../assets/images/jobs.png";
import Job2 from '../../assets/images/job2.png';

import './Curriculo.css';
import SeparatorBar from "../../components/SeparatorBar/SeparatorBar";

const Curriculo = () => {
  return (
    <>
      <div className="jobs">
        <img src={Jobs} alt="" />
      </div>
      <div className="separator-jobs">
        <SeparatorBar />
        <div className="container">
          <div className="title-jobs">
            <h3> <img src={Job2} alt="" />Currículo online</h3>
          </div>
        </div>
      </div>
      <Formulario />
    </>
  )
}
export default Curriculo;