import Gamer5 from '../../assets/images/gamer5-Photoroom.png';

import './FotoSlide.css';

const FotoSlide = () => {
  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-sm-12'>
          <div className='foto-gamer'>
            <img src={Gamer5} alt="foto-gamer" />
          </div>
        </div>
      </div>
    </div>
  )
}
export default FotoSlide;