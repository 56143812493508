import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './RuralInternet.css';
import ruralImage from '../../../assets/images/4GLTE-Photoroom.png';
import SeparatorBar from '../../SeparatorBar/SeparatorBar';

const RuralInternet = () => {
  return (
    <>
      <SeparatorBar />
      <Container className="rural-internet my-5">
        <h1 className="title-rural mb-4">Internet Rural com Tecnologia 4G LTE</h1>
        <Row className="align-items-center">
          {/* <Col md={6}>
            <Card className="rural-card">
              <Card.Img variant="top" src={ruralImage} alt="Internet Rural" />
            </Card>
          </Col> */}
          <Col md={12}>
            <Card className="rural-card">
              <Card.Body>
                <Card.Text className="lead">
                  Nossa internet rural com tecnologia 4G LTE oferece velocidade e confiabilidade para áreas remotas. 
                  Agora, você pode desfrutar de streaming de vídeo, redes sociais e muito mais, com a mesma qualidade 
                  das áreas urbanas.
                </Card.Text>
                <Card.Text className="lead">
                  Com uma cobertura extensa e uma instalação rápida e fácil, nossa tecnologia 4G LTE é a melhor escolha 
                  para conectar você ao mundo digital. Consulte disponibilidade para sua região.
                </Card.Text>
                <Card.Text className="lead">
                  <strong>Qualidade do Serviço:</strong>
                  <ul>
                    <li>Velocidade de até 10 Mbps</li>
                    <li>Suporte técnico disponível também nos feriados e finais de semana.</li>
                    <li>Planos flexíveis para atender às suas necessidades</li>
                    <li>Oportunidade para adquirir telefone fixo via internet</li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default RuralInternet;
