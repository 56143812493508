import React from 'react';

const GigaPlusHD = () => (
  <div>
    <h2>GIGA PLUS HD R$ 85,00</h2>
    <p>Leve o seu entretenimento a um novo nível com o plano GIGA PLUS HD. 
      Com 47 canais pagos e mais 40 canais livres, você terá uma vasta gama de opções para toda a família. 
      Desde esportes e filmes até documentários e programas infantis, há algo para todos. 
      Tudo isso com a praticidade de assistir pela internet.
    </p>
  </div>
);

export default GigaPlusHD;