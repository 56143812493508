import React, { useState, useEffect } from "react";
import Logo from '../../assets/images/logoGiga2.png';
import Google from '../../assets/images/googleplay.png';
import AppleStore from '../../assets/images/AppStore.png';
import { useMediaQuery } from 'react-responsive';

import 'font-awesome/css/font-awesome.min.css';
import './styles.css';


const Footer = () => {

    const message = "Internet, TV por Assinatura, Telefonia Fixa, Internet Rural";

    const [whatsAppLink, setWhatsAppLink] = useState("");

    useEffect(() => {
        const userAgent = navigator.userAgent.toLowerCase();
        const baseURL = "https://wa.me/556222000000";
        const messageParam = encodeURIComponent(`Olá, entre para falar sobre: ${message}`);

        if (/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/.test(userAgent)) {
            setWhatsAppLink(`whatsapp://send?phone=556222000000&text=${messageParam}`);
        } else {
            setWhatsAppLink(`${baseURL}?text=${messageParam}`);
        }
    }, [message]);

    const isMobile = useMediaQuery({ maxWidth: 768 });

    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            console.log('ScrollY:', window.scrollY); // Depuração
            if (window.scrollY > 300) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <>
            <div className="container-fluid">
                <div className="row my-row-footer">
                    <div className="col-sm-12 col-md-5 my-col-footer">
                        <h1>Gigabyte</h1>
                        <p>
                            A GigaByte Telecom e uma operadora de telecomunicações
                            que oferece soluções em Internet, TV a cabo e Telefonia Fixa.
                        </p>
                        <div className="store">
                            <a href="https://play.google.com/store/apps/details?id=com.gigabytemobile&hl=pt_BR&pli=1" target="_blank" rel="noopener noreferrer">
                                <img src={Google} alt="" />
                            </a>
                            <a href="https://apps.apple.com/br/app/gigabyte-play/id6443457059?l=pt" target="_blank" rel="noopener noreferrer">
                                <img src={AppleStore} alt="" />
                            </a>
                        </div>
                    </div>
                    {isMobile ? <div></div> : (
                        <div className="col-sm-12 col-md-4 footer-links">
                            <h3>Mapa de Navegação</h3>
                            <ul>
                                <li>Página Incial</li>
                                <li>Planos Internet</li>
                                <li>Planos Internet Rural</li>
                                <li>TV por Assinatura</li>
                                <li>Telefonia Fixa</li>
                                <li>Giga Multimídia</li>
                                <li>Quem Somos</li>
                                <li>Política de Privacidade</li>
                                <li>Política de Cookies</li>
                            </ul>
                            <br />
                        </div>
                    )}
                    <div className="col-sm-12 col-md-3 contact-social">
                        <div className="footer-contact">
                            <h3>Entre em Contato</h3>
                            <p>(62) 2200-0000 | 0800 062 2200</p>
                            <p>faleconosco@gigabytetelecom.com.br</p>
                        </div>
                        <h3>Siga-nos:</h3>
                        <div className="footer-contact-social">
                            <h2 className="icons">
                                <a href={whatsAppLink} target="_blank"><i className="fa fa-whatsapp" aria-hidden="true"></i></a>
                                <a target="_blank" href="https://www.facebook.com/gigabytetelecom"><i className="fa fa-facebook-official" aria-hidden="true"> </i></a>
                                <a target="_blank" href="https://www.instagram.com/gigabytetelecom/"><i className="fa fa-instagram" aria-hidden="true"> </i></a>
                            </h2>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer">
                {isMobile ? (
                    <div className='logo-mob'>
                        <img src={Logo} alt='logo2' />
                    </div>

                ) : (
                    <div className='footer'>
                        <div className='copyright'>© 2007 - 2024 - Gigabyte Telecom</div>
                        <div className='logo'><img src={Logo} alt='logo2' /></div>
                        <div className='private'>Políticas De Privacidade - Políticas De Cookies</div>
                    </div>
                )}
            </div>
            {showButton && (
                <button className="scroll-to-top" onClick={scrollToTop}>
                    <i className="fa fa-arrow-up" aria-hidden="true"></i>
                </button>
            )}

            <div>

            </div>
        </>
    );
}

export default Footer;
