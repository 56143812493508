import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import './RuralInternetPlan.css';
import SeparatorBar from '../../SeparatorBar/SeparatorBar';

const RuralInternetPlan = () => {
  return (
    <>
      <div className='rural-plan'>
        <Container className="rural-internet-plan my-5">
          <br />
          <h1 className="text-center-rural mb-4">Plano Internet Rural</h1>
          <Row className="justify-content-center">
            <Col md={12}>
              <Card className="plan-card text-center">
                <Card.Body>
                  <Card.Text>
                    <h2>Aproveite nossa promoção!</h2>
                    <SeparatorBar />
                  </Card.Text>
                  <Card.Text className="lead">
                    <strong>Valor promocional R$ 400,00*</strong> para pagamento à vista!<br />
                    Consulte valores fora da promoção.
                  </Card.Text>
                  <Card.Text className="lead">
                    <strong>A partir de R$ 99,99*</strong> mensal.<br />
                    Promoção válida para pagamentos até a data do vencimento.
                  </Card.Text>
                  <Card.Text className="lead">
                    Consulte disponibilidade através da localização.<br />
                    Você poderá solicitar sua internet através das regiões disponíveis: Jaraguá, Ceres, Rialma, Uruana, Rubiataba e Uruaçu.
                  </Card.Text>
                  <Card.Text className="lead">
                    <strong>Incluso no kit:</strong>
                    <ul>
                      <li>Uma antena</li>
                      <li>Chip incluso na antena</li>
                      <li>Um Roteador com sinal wi-fi acoplado</li>
                    </ul>
                    <em>Obs: Não é necessário comprar nenhum equipamento, todo o kit incluso é em comodato.</em>
                  </Card.Text>
                  <Button variant="primary" className="btn-signup">CONTRATE AGORA</Button>
                  <p className="text-muted mt-3">Clique em assinar para verificar a disponibilidade para sua localidade.</p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        <br />
      </div>
    </>
  );
};

export default RuralInternetPlan;
