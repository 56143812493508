import React from 'react';
import './Cidades.css';

const cities = [
  { name: 'Carmo Do Rio Verde', address: 'Endereço 1', phone: '0800 062-2200', contact: 'contato@gigabytetelecom.com.br' },
  { name: 'Ceres', address: 'Endereço 2', phone: '(62) 2201-0000', contact: 'contato@gigabytetelecom.com.br' },
  { name: 'Goiânia', address: 'Endereço 3', phone: '(62) 2203-0000', contact: 'contato@gigabytetelecom.com.br' },
  { name: 'Aparecida De Goiânia', address: 'Endereço 4', phone: '0800 062-2200', contact: 'contato@gigabytetelecom.com.br' },
  { name: 'Jaraguá', address: 'Endereço 5', phone: '(62) 2200-0000', contact: 'contato@gigabytetelecom.com.br' },
  { name: 'Rialma', address: 'Endereço 6', phone: 'Rialma (62) 2201-0000', contact: 'contato@gigabytetelecom.com.br' },
  { name: 'Rianápolis', address: 'Endereço 7', phone: '0800 062-2200', contact: 'contato@gigabytetelecom.com.br' },
  { name: 'Uruana', address: 'Endereço 8', phone: '0800 062-2200', contact: 'contato@gigabytetelecom.com.br' },
  { name: 'Uruaçu', address: 'Endereço 9', phone: '(62) 2207-0000', contact: 'contato@gigabytetelecom.com.br' },
  { name: 'Uruceres', address: 'Endereço 10', phone: '0800 062-2200', contact: 'contato@gigabytetelecom.com.br' },
  { name: 'Braslândia', address: 'Endereço 11', phone: '0800 062-2200', contact: 'contato@gigabytetelecom.com.br' },
  { name: 'Nova Gloria', address: 'Endereço 12', phone: '0800 062-2200', contact: 'contato@gigabytetelecom.com.br' },
  { name: 'São Francisco', address: 'Endereço 13', phone: '0800 062-2200', contact: 'contato@gigabytetelecom.com.br' },
];

const Cidades = () => {
  return (
    <div className="cidades-container">
      <h1 className="cidades-title">Nossas Lojas</h1>
      <div className="cidades-grid">
        {cities.map((city, index) => (
          <div key={index} className="cidade-card">
            <h2>{city.name}</h2>
            {/* <p><strong>Endereço:</strong> {city.address}</p> */}
            <p><strong>Telefone:</strong> <a href={`tel:${city.phone}`}>{city.phone}</a></p>
            <p><strong>Contato:</strong> <a href={`mailto:${city.contact}`}>{city.contact}</a></p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Cidades;
