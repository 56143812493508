import React from 'react';
// import GigaLivre from '../GigaLivre/GigaLivre';
import GigaStart from '..//GigaStart/GigaStart';
import GigaPlus from '../GigaPlus/GigaPlus';
import GigaFull from '../GigaFull/GigaFull';

const CanaisList = ({ plan }) => {
  switch(plan) {
    // case 'GIGA LIVRE HD + INTERNET':
    //   return <GigaLivre />;
    case 'GIGA START HD + INTERNET':
      return <GigaStart />;
    case 'GIGA PLUS HD + INTERNET':
      return <GigaPlus />;
    case 'GIGA FULL HD + INTERNET':
      return <GigaFull />;
    default:
      return null;
  }
};

export default CanaisList;
