import React from 'react';

const GigaFullHD = () => (
  <div>
    <h2>GIGA FULL HD R$ 105,00</h2>
    <p>
    Experimente o máximo em entretenimento com o plano GIGA FULL HD. 
    Com 56 canais pagos e mais 40 canais livres, este é o pacote completo para quem não quer perder nada. 
    Filmes de sucesso, séries aclamadas, esportes ao vivo, documentários e muito mais, tudo em alta definição.  
    Aproveite a liberdade e a flexibilidade que nosso App Gigabyte Play TV oferece.
    </p>
  </div>
);

export default GigaFullHD;