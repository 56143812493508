import React, { useState } from 'react';
import { Container, Row, Col, Card, Button, Modal, Tabs, Tab } from 'react-bootstrap';
import CanaisList from '../../CanaisList/CanaisList';
import WifiTV from '../../../../assets/images/wifiTv.png';
import TV from '../../../../assets/icons/Icontv2.png';
import '../ComboTV.css'; // Importando o arquivo de estilos
import GigaLivre from '../../GigaLivre/GigaLivre';

const plansData = {
  'GIGA LIVRE HD + INTERNET': [
    {
      name: '10 MEGAS',
      internet: 'INTERNET 10 MEGAS',
      internetprice: 'R$ 50,00',
      discountedprice: 'R$ 35,00',
      tv: 'GIGA LIVRE HD',
      tvprice: 'R$ 25,00',
      price: 'R$ 60,00*/mês',
      canais: 'MAIS DE 40 CANAIS'
    },
    {
      name: '60 MEGAS',
      internet: 'INTERNET 60 MEGAS',
      internetprice: 'R$ 60,00',
      discountedprice: 'R$ 45,00',
      tv: 'GIGA LIVRE HD',
      tvprice: 'R$ 25,00',
      price: 'R$ 70,00*/mês',
      canais: 'MAIS DE 40 CANAIS'
    },
    {
      name: '400 MEGAS',
      internet: 'INTERNET 400 MEGAS',
      internetprice: 'R$ 100,00',
      discountedprice: 'R$ 85,00',
      tv: 'GIGA LIVRE HD',
      tvprice: 'R$ 25,00',
      price: 'R$ 110,00*/mês',
      canais: 'MAIS DE 40 CANAIS'
    },
    {
      name: '600 MEGAS',
      internet: 'INTERNET 600 MEGAS',
      internetprice: 'R$ 120,00',
      discountedprice: 'R$ 105,00',
      tv: 'GIGA LIVRE HD',
      tvprice: 'R$ 25,00',
      price: 'R$ 130,00*/mês',
      canais: 'MAIS DE 40 CANAIS'
    },
  ],
  'GIGA START HD + INTERNET': [
    {
      name: '10 MEGAS',
      internet: 'INTERNET 10 MEGAS',
      internetprice: 'R$ 50,00',
      discountedprice: 'R$ 35,00',
      tv: 'GIGA START HD',
      tvprice: 'R$ 65,00',
      price: 'R$ 100,00*/mês',
      canais: 'MAIS DE 64 CANAIS'
    },
    {
      name: '60 MEGAS',
      internet: 'INTERNET 60 MEGAS',
      internetprice: 'R$ 60,00',
      discountedprice: 'R$ 45,00',
      tv: 'GIGA START HD',
      tvprice: 'R$ 65,00',
      price: 'R$ 110,00*/mês',
      canais: 'MAIS DE 64 CANAIS'
    },
    {
      name: '400 MEGAS',
      internet: 'INTERNET 400 MEGAS',
      internetprice: 'R$ 100,00',
      discountedprice: 'R$ 85,00',
      tv: 'GIGA START HD',
      tvprice: 'R$ 65,00',
      price: 'R$ 150,00*/mês',
      canais: 'MAIS DE 64 CANAIS'
    },
    {
      name: '600 MEGAS',
      internet: 'INTERNET 600 MEGAS',
      internetprice: 'R$ 120,00',
      discountedprice: 'R$ 105,00',
      tv: 'GIGA START HD',
      tvprice: 'R$ 65,00',
      price: 'R$ 170,00*/mês',
      canais: 'MAIS DE 64 CANAIS'
    },

  ],
  'GIGA PLUS HD + INTERNET': [
    {
      name: '10 MEGAS',
      internet: 'INTERNET 10 MEGAS',
      internetprice: 'R$ 50,00',
      discountedprice: 'R$ 35,00',
      tv: 'GIGA PLUS HD',
      tvprice: 'R$ 85,00',
      price: 'R$ 120,00*/mês',
      canais: 'MAIS DE 87 CANAIS'
    },
    {
      name: '60 MEGAS',
      internet: 'INTERNET 60 MEGAS',
      internetprice: 'R$ 60,00',
      discountedprice: 'R$ 45,00',
      tv: 'GIGA PLUS HD',
      tvprice: 'R$ 85,00',
      price: 'R$ 130,00*/mês',
      canais: 'MAIS DE 87 CANAIS'
    },
    {
      name: '400 MEGAS',
      internet: 'INTERNET 400 MEGAS',
      internetprice: 'R$ 100,00',
      discountedprice: 'R$ 85,00',
      tv: 'GIGA PLUS HD',
      tvprice: 'R$ 85,00',
      price: 'R$ 170,00*/mês',
      canais: 'MAIS DE 87 CANAIS'
    },
    {
      name: '600 MEGAS',
      internet: 'INTERNET 600 MEGAS',
      internetprice: 'R$ 120,00',
      discountedprice: 'R$ 105,00',
      tv: 'GIGA PLUS HD',
      tvprice: 'R$ 85,00',
      price: 'R$ 190,00*/mês',
      canais: 'MAIS DE 87 CANAIS'
    },
  ],
  'GIGA FULL HD + INTERNET': [
    {
      name: '10 MEGAS',
      internet: 'INTERNET 10 MEGAS',
      internetprice: 'R$ 50,00',
      discountedprice: 'R$ 35,00',
      tv: 'GIGA FULL HD',
      tvprice: 'R$ 105,00',
      price: 'R$ 140,00*/mês',
      canais: 'MAIS DE 96 CANAIS'
    },
    {
      name: '60 MEGAS',
      internet: 'INTERNET 60 MEGAS',
      internetprice: 'R$ 60,00',
      discountedprice: 'R$ 45,00',
      tv: 'GIGA FULL HD',
      tvprice: 'R$ 105,00',
      price: 'R$ 150,00*/mês',
      canais: 'MAIS DE 96 CANAIS'
    },
    {
      name: '400 MEGAS',
      internet: 'INTERNET 400 MEGAS',
      internetprice: 'R$ 100,00',
      discountedprice: 'R$ 85,00',
      tv: 'GIGA FULL HD',
      tvprice: 'R$ 105,00',
      price: 'R$ 190,00*/mês',
      canais: 'MAIS DE 96 CANAIS'
    },
    {
      name: '600 MEGAS',
      internet: 'INTERNET 600 MEGAS',
      internetprice: 'R$ 120,00',
      discountedprice: 'R$ 105,00',
      tv: 'GIGA FULL HD',
      tvprice: 'R$ 105,00',
      price: 'R$ 210,00*/mês',
      canais: 'MAIS DE 96 CANAIS'
    },

  ],
};

const ComboWrapper = () => {
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [showChannels, setShowChannels] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [selectedPlanData, setSelectedPlanData] = useState([]);

  const handleShowMoreInfo = (title) => {
    setSelectedPlan(title);
    setSelectedPlanData(plansData[title]);
    setShowMoreInfo(true);
  };

  const handleShowChannels = (title) => {
    setSelectedPlan(title);
    setShowChannels(true);
  };

  const handleClose = () => {
    setShowMoreInfo(false);
    setShowChannels(false);
  };

  return (
    <>
      <Container className="app-giga-play my-5">
        <h1 className="text-center-title mb-4">Combo, Internet + TV</h1>
        <p>
          Assista seus programas favoritos a qualquer hora e em qualquer lugar com o App Giga Play. Disponível para Android, iOS, TVs Samsung e LG.
        </p>
        <Row className="my-4">
          {Object.keys(plansData).map((title, index) => (
            <Col key={index} md={3} sm={6}>
              <Card className="combo-card">
                <Card.Img variant="top" src={WifiTV} alt={title} className="combo-image" />
                <Card.Header>Internet + TV</Card.Header>
                <Card.Body>
                  <Card.Title>{title}</Card.Title>
                  <Card.Text>
                    Desconto de R$15,00 na Internet assinando o Combo
                  </Card.Text>
                  <Button className="mais-button" onClick={() => handleShowMoreInfo(title)}>
                    Mais informações
                  </Button> {' '}
                  <Button className="canais-button" onClick={() => handleShowChannels(title)}>
                    Ver canais
                  </Button >
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>

      {/* Modal para Mais Informações */}
      <Modal show={showMoreInfo} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title> <div className='icon-tv'> <img src={TV} alt='icon-tv' /> {selectedPlan}</div></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedPlanData.length > 0 && (
            <Tabs defaultActiveKey={selectedPlanData[0].name} id="plans-tabs">
              {selectedPlanData.map((plan, index) => (
                <Tab eventKey={plan.name} title={plan.name} key={index}>
                  <Row className="plan-details">
                    <Col md={6}>
                      <h4 className="plan-canais">{plan.canais}</h4>
                      <h4 className="plan-tv">{plan.tv}</h4>
                      <h4 className="plan-tvprice">{plan.tvprice}</h4>
                    </Col>
                    <Col md={6}>
                      <h4 className='plan-internet'>{plan.internet}</h4>
                      <div className='plan-price-discounted'>
                        <h4 className="plan-internetprice"> de {plan.internetprice}</h4>
                        <h4 className='plan-discountedprice'> por {plan.discountedprice}</h4>
                      </div>
                      <h2 className="plan-price">{'TOTAL ' + plan.price}</h2>
                    </Col>
                  </Row>
                </Tab>
              ))}
            </Tabs>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className='plan-span'>
          <span>
            *Promoção válida para pagamento até a data do vencimento e adesão do plano conta sem papel.*
          </span>
          <span>
            *Oferta exclusiva para clientes Gigabyte Telecom, consulte condições de disponibilidade e instalação para novos clientes.*
          </span>
          </div>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal para Ver Canais */}
      <Modal show={showChannels} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Lista de Canais</Modal.Title>
        </Modal.Header>
        <Modal.Body>    
          <CanaisList plan={selectedPlan} /> 
          <GigaLivre />         
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ComboWrapper;
